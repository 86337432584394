const envs = new Map([
  ['vm-silbo-e4-fc.pago.chat:47360', 'DEV'],
  ['vm-silbo-e3-fc.pago.chat:47360', 'QA'],
  ['pagochat-qa.pago.chat', 'QA-LEGACY'],
  ['pagochat-uat.pago.chat', 'UAT'],
  ['pagochat-uat.pagachateando.com', 'UAT'],
  ['customer.pago.chat', 'PROD'],
]);

export const isProd = () => typeof window !== 'undefined' && envs.get(window?.location.hostname) === 'PROD';
