import { useEffect } from 'react';
import { isProd } from '../utils/domains';
import { removeNodes } from '../utils/dom';

const RECAPTCHA_SITE_KEY_NOT_PROD = '6LcQo1oaAAAAAFvqZPv2O9VhIEZXAPdipHLmMCpw';
const RECAPTCHA_SITE_KEY_PROD = '6Lfx4TgbAAAAADR3pJzJy7W-NSUIQ14zJ3pev5CR';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const useRecaptcha = (load: boolean = true) => {
  const SITE_KEY = isProd() ? RECAPTCHA_SITE_KEY_PROD : RECAPTCHA_SITE_KEY_NOT_PROD;

  useEffect(() => {
    if (!load) return;
    const loadScriptByURL = (id: string, url: string, callback?: () => void) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL('grecaptcha', `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);

    return () => {
      removeNodes(['#grecaptcha', '.grecaptcha-badge']);
    };
  }, [load]);

  const getRecaptchaToken = () => {
    return new Promise(resolve => {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(SITE_KEY, { action: 'submit' });
        resolve(token);
      });
    });
  };

  return {
    getRecaptchaToken,
  };
};
