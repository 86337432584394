import React, { useState, useContext, useEffect } from 'react';
import { EnterPINForm } from '../../../components/287634/form/pin/enter-pin-form';
import { EnterPINStatus } from '../../../components/287634/form/pin/enter-pin-status';
import Layout from '../../../components/287634/layout/layout';
import appService from '../../../services/endpoints';
import pages from '../../../constants/pages.json';
import { CreditcardContext } from '../../../context/creditcard.context';
import { navigateToCustomPage, navigateToError, navigateToSuccess } from '../../../utils/navigate';
import { useRecaptcha } from '../../../hooks/recaptcha.hook';
import { removeNodes } from '../../../utils/dom';
import { H2 } from '@/components/287634/typography';

const EnterPINPage = ({ getToken }: PageProps) => {
  const [status, setStatus] = useState<string>('');
  const { setLoading } = useContext(CreditcardContext);
  const { getRecaptchaToken } = useRecaptcha();
  const [invalidPIN, setInvalidPIN] = useState(false);
  const [resettingPinState, setResettingPinState] = useState<'idle' | 'loading' | ''>('idle');
  const token = getToken();

  const handleSubmit = async (pin: string, isWebAuthn: boolean) => {
    const recaptchaToken = await getRecaptchaToken();
    appService
      .patchEnterPin(token, pin, recaptchaToken, isWebAuthn)
      .then(({ action, url, feedback }) => {
        if (action === 'redirect') {
          return navigateToCustomPage(`/${url}`)?.();
        }
        if (action === 'paid') {
          return navigateToSuccess({
            state: { from: pages.SUCCESS_PAGE.PAID, response: feedback },
            callback: () => setLoading?.(false),
          })?.();
        }
        if (action === 'custom_error') {
          if (feedback.error_code === 'FGS1900') {
            return setInvalidPIN(true);
          } else if (feedback.error_code==='FGS1902'){
            return setStatus('locked');
          } else if (feedback.error_code==='FGS1903'){
              return setStatus('lockedTotally');
          }
          navigateToError()?.();
        }
        if (action === 'finished') {
          return setStatus('success');
        }
        // @todo: we might want to redirect to generic error page if server
        // response is malformed or unknown.
        throw new Error('Unknown response.');
      })
      .catch(error => {
        console.warn(`Request failed; ${error}`);
          if (error.message === 'FGS1900') {
              return setInvalidPIN(true);
          } else if (error.message==='FGS1902'){
              return setStatus('locked');
          } else if (error.message==='FGS1903'){
              return setStatus('lockedTotally');
          }
        return setInvalidPIN(true);
      });
  };

  const handleChangePin = (isInvalid: boolean) => setInvalidPIN(isInvalid);

  const handleResetPin = async () => {
    setResettingPinState('loading');
    await appService.resendEmailCode(token);
    navigateToCustomPage(`${pages.EMAIL_CODE}?${token}`, { replace: true })?.();
  };

  useEffect(() => {
    return () => {
      removeNodes(['#grecaptcha', '.grecaptcha-badge']);
    };
  }, []);

  if (status) {
    return <EnterPINStatus statusType={status} />;
  }

  return (
    <Layout>
      <H2 className="mx-5 mb-10 mt-[60px] text-center text-primary">Ingresa tu código de seguridad</H2>
      <EnterPINForm
        onSubmit={handleSubmit}
        invalidPIN={invalidPIN}
        onChangePIN={handleChangePin}
        onResetPIN={handleResetPin}
        resettingPinState={resettingPinState}
      />
    </Layout>
  );
};

export default EnterPINPage;
