const addLink = ({
  href,
  id,
  onload,
  onerror,
}: {
  href: string;
  id: string;
  onload?: () => void;
  onerror?: () => void;
}) => {
  if (document.querySelector(`#${id}`) === null) {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.href = href;
    link.id = id;
    link.rel = 'stylesheet';
    link.onload = onload || null;
    link.onerror = onerror || null;
    document.head.appendChild(link);
  }
};

const addScript = ({
  src,
  id,
  onload,
  onerror,
}: {
  src: string;
  id: string;
  onload?: () => void;
  onerror?: () => void;
}) => {
  if (document.querySelector(`#${id}`) === null) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = onload || null;
    script.onerror = onerror || null;
    document.body.appendChild(script);
  }
};

const removeNodes = (selectors: string[]) => {
  selectors.forEach((selector: string) => {
    const element = document.querySelector(selector);
    const parentElement = element?.parentNode;
    element && parentElement?.removeChild(element);
  });
};

export { addLink, addScript, removeNodes };
