import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../layout/layout';
import { returnWhatsapp } from '../../../../utils/browser';
import { useStatus, StatusProps } from '../../../../hooks/pin.hook';
import { LangContext } from '../../../../context/lang.context';
import Button from '../../button/button';
import { H1, H2, P } from '../../typography';

export const EnterPINStatus = ({ statusType }: StatusProps) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETURN_WHATSAPP
        }
      }
    }
  `);
  const { status } = useStatus({ statusType });

  return (
    <Layout>
      <section className="px-5 text-center">
        <H2 className="mx-auto mt-[60px] mb-6 text-primary">Código de seguridad</H2>
      </section>
      <section className="flex w-full flex-col items-center">
        <img className="w-2/3" src={status.image} alt="Status" />
      </section>
      <section className="flex flex-col text-center text-primary">
        <H1 className="mx-5 mb-6" dangerouslySetInnerHTML={{ __html: status.title }}></H1>
          {status.error && status.paragraph && <span className="font-semibold text-sm text-red">
              <P className="mx-5 mb-10 ">{status.paragraph}</P>
          </span>}
          {!status.error && status.paragraph && <P className="mx-5 mb-10 ">{status.paragraph}</P> }
      </section>
      <section>
        <Button onClick={() => returnWhatsapp()}>{t(data).RETURN_WHATSAPP}</Button>
      </section>
    </Layout>
  );
};
