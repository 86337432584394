import { useContext, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CompanyContext } from '../context/company.context';
import { LangContext } from '../context/lang.context';

type Status = {
  title: string;
  image: string;
  paragraph?: string;
  error?: boolean;
  whatsapp?: string;
};

export type StatusProps = {
  statusType: string;
};

export const useStatus = ({ statusType }: StatusProps) => {
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          ENTER_PIN {
            ERROR_AVAILABILITY {
              TITLE
            }
            ERROR_VERIFICATION {
              TITLE_1
            }
            ERROR_LOCKED {
              DESCRIPTION
              TITLE
            }
            SUCCESS {
              TITLE
            }
          }
        }
      }
    }
  `);
  const { t } = useContext(LangContext);
  const { getPublicURL } = useContext(CompanyContext);
  const statusScenes: { [key: string]: Status } = {
    success: {
      title: '¡Genial! <p>Código de seguridad correcto.</p>',
      image: getPublicURL('/theme/assets/images/feedback/generic-success.svg'),
    },
    locked: {
      title: "",
      image: getPublicURL('/theme/assets/images/error/generic-error.svg'),
      paragraph: "!Oh no! Código de seguridad bloqueado. Has superado el límite de intentos permitidos. Inténtalo más tarde.",
      error: true,
    },
    lockedTotally: {
      title: "",
      image: getPublicURL('/theme/assets/images/error/generic-error.svg'),
      paragraph: "Hemos bloqueado tu cuenta por motivos de seguridad. Contacta con nuestro equipo para resolverlo.",
      error: true,
    },
    unverified: {
      title: t(data).ENTER_PIN.ERROR_VERIFICATION.TITLE_1,
      image: getPublicURL('/theme/assets/images/error/generic-error.svg'),
      error: true,
    },
    unavailable: {
      title: t(data).ENTER_PIN.ERROR_AVAILABILITY.TITLE,
      image: getPublicURL('/theme/assets/images/error/generic-error.svg'),
      error: true,
    },
  };

  const [newStatusType, setStatusType] = useState<string>(statusType);

  return {
    status: statusScenes[newStatusType],
    setStatus: setStatusType,
  };
};
