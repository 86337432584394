import React, { ChangeEvent, useState, useRef, KeyboardEvent } from 'react';

type PinProps = {
  value?: string;
  onChange?: (e: ChangeEvent) => any;
  onKeyUp?: (e: KeyboardEvent) => any;
  warning?: string;
};

const Pin = ({ value, onChange, onKeyUp, warning }: PinProps) => {
  const ONLY_NUMBERS_REGEX = /^\d*$/;
  const inputRef = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<[string, string]>([value && value.replaceAll(/\d/gi, '*'), value || '']);
  const [showValue, setShowValue] = useState(false);

  return (
    <div
      className={`relative h-[52px] w-60 rounded-lg px-4 pt-3 focus-within:outline focus-within:outline-2 focus-within:outline-cyan ${
        warning ? 'bg-red-50' : 'bg-gray-100'
      }`}
    >
      <span className="absolute left-4 right-10 top-0 text-4xl font-light leading-snug tracking-[32px] text-primary">
        {showValue ? values[1] : values[0]}
      </span>
      <input
        className="absolute inset-0 overflow-hidden bg-transparent opacity-0 focus:outline-none"
        value={value}
        ref={inputRef}
        onChange={(e: ChangeEvent) => {
          onChange(e);
          const newValue = (e.target as HTMLInputElement).value;
          setValues([newValue.replaceAll(/\d/gi, '*'), newValue]);
        }}
        onKeyPress={e => !ONLY_NUMBERS_REGEX.test(e.key) && e.preventDefault()}
        onKeyUp={onKeyUp}
        maxLength={4}
        inputMode="numeric"
      />
      <div
        role="show-pin"
        className="absolute right-0 top-1/2 w-10 -translate-y-1/2 transform cursor-pointer p-2 pt-3 text-center"
        onClick={() => {
          setShowValue(!showValue);
          inputRef.current?.focus();
        }}
      >
        {showValue ? <i className="icon-eye-open" /> : <i className="icon-eye-stroke" />}
      </div>
      {warning && <span className="absolute left-0 top-full pl-1 pt-1 text-xs font-medium text-red">{warning}</span>}
    </div>
  );
};

export { Pin };
