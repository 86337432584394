import React, { useContext, ChangeEvent, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CompanyContext } from '../../../../context/company.context';
import Button from '../../button/button';
import { P } from '../../typography';
import { Pin } from '../input/pin';
import {returnWhatsapp} from "@/utils/browser";

type Props = {
  onSubmit: (pin: string, isWebAuthn: boolean) => void;
  invalidPIN: boolean;
  onChangePIN: (value: boolean) => void;
  onResetPIN: () => void;
  resettingPinState: 'idle' | 'loading' | '';
};

export const EnterPINForm = ({ onSubmit, invalidPIN, onChangePIN, onResetPIN, resettingPinState }: Props) => {
  const { getPublicURL } = useContext(CompanyContext);

  const formik = useFormik({
    initialValues: { pin: '', isWebAuthn: false },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      pin: Yup.string(),
      isWebAuthn: Yup.boolean(),
    }),
    onSubmit(values: { pin: string; isWebAuthn: boolean }, { setSubmitting }) {
      if (!formik.values.pin || formik.errors.pin) {
        setSubmitting(false);
        return;
      }
      setSubmitting(true);
      onSubmit(values.pin, false);
    },
  });

  useEffect(() => {
    if (invalidPIN) {
      formik.setErrors({
        pin: 'Código de seguridad incorrecto. Ingrésalo de nuevo. El código podría bloquearse.',
      });
      formik.setSubmitting(false);
    } else {
      formik.setErrors({
        pin: null,
      });
    }
  }, [invalidPIN]);

  return (
    <section className="flex w-full flex-auto flex-col items-center justify-between">
      <section className="flex w-full flex-col items-center">
        <img className="mb-5 w-2/3" src={getPublicURL(`/theme/assets/images/pin/pin.svg`)} alt="PIN" />
        <Pin
          value={formik.values.pin}
          onChange={(event: ChangeEvent) => {
            formik.setFieldValue('pin', (event.target as HTMLInputElement).value);
            onChangePIN(false);
            formik.setSubmitting(false);
          }}
          onKeyUp={event => event.key === 'Enter' && !formik.isSubmitting && formik.submitForm()}
        />
        <div className="px-10 mt-1 w-full text-center" hidden={!formik.errors.pin}>
          <span className="font-semibold text-sm text-red">{formik.errors.pin}</span>
        </div>
      </section>
      <section className="mt-8">
        <button className="text-[17px] leading-[1.2em] text-primary underline font-medium"
           onClick={() => returnWhatsapp({ message: 'Recuperar código de seguridad' })}
           >
          ¿Has olvidado tu código de seguridad?
        </button>
      </section>
      <section className="mt-10 flex flex-col items-center">
        <Button
          type="submit"
          onClick={formik.submitForm}
          disabled={formik.isSubmitting || resettingPinState === 'loading' || invalidPIN}
        >
          {formik.isSubmitting ? 'Espera un momento...' : 'Enviar'}
        </Button>
      </section>
    </section>
  );
};
